@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stroke-round {
  stroke-linejoin: round;
}

.DayPicker-Day {
  padding: 0;
  width: 2em;
  height: 2em;
}

.DayPicker-Day--selected {
  border-radius: 0;
}

.DayPicker-Day--start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.DayPicker-Day--end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.from-transparent-white {
  --tw-gradient-from: rgba(255, 255, 255, 0);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
